@import "src/styles/variables";

/* Button */

.ant-btn {
  border-radius: 8px;
  font-weight: bold;
  &.large-btn {
    &:not(.ant-btn-icon-only) {
      padding: 10px 40px;
    }
  }

  &:not(.ant-btn-icon-only) {
    padding: 10px 40px;
    height: auto;
  }

  &.ant-btn-icon-only {
    font-size: 10px;
    text-align: center;
    color: #ffffff;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: $secondary-color;
    &.delete-btn {
      background-color: $danger-color;
    }
  }
}

.ant-btn-link {
  color: $primary-color;
  &:hover,
  &:active,
  &:focus {
    color: $primary-color;
  }
}

.ant-btn-default {
  border-color: transparent;
  color: $secondary-black;
  &:hover,
  &:active,
  &:focus {
    color: $secondary-black;
    border-color: transparent;
  }
}

.ant-btn-primary {
  border: 1px solid $primary-color;
  background-color: $primary-color;
  &:hover,
  &:active,
  &:focus {
    border: 1px solid $primary-color;
    background-color: $primary-color;
  }
}

.ant-btn.secondary-btn {
  background-color: $secondary-color;
  border: 1px solid $secondary-color;
  color: #ffffff;
  &:hover,
  &:active,
  &:focus {
    background-color: $secondary-color;
    border: 1px solid $secondary-color;
  }
}

.ant-btn.danger-btn {
  background-color: $danger-color;
  border: 1px solid $danger-color;
  color: #ffffff;
  &:hover,
  &:active,
  &:focus {
    background-color: $danger-color;
    border: 1px solid $danger-color;
  }
}

.ant-btn.warning-btn {
  background-color: $warning-color;
  border: 1px solid $warning-color;
  color: #ffffff;
  &:hover,
  &:active,
  &:focus {
    background-color: $warning-color;
    border: 1px solid $warning-color;
  }
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  background-color: $primary-light-color;
  color: #ffffff;
}

/* Table */

.ant-table-wrapper {
  width: 100%;
  .ant-table .ant-table-content table {
    border-collapse: separate;
    border-spacing: 0 1rem;
    background-color: $body-bg;
  }

  thead.ant-table-thead tr th {
    background-color: $body-bg;
    border: none;
    color: $secondary-black;
    font-weight: bold;
  }

  tbody.ant-table-tbody tr {
    box-shadow: $primary-shadow;
    & > td {
      background-color: #ffffff;
      border: none;
      padding: 25px 30px;
      &:first-child {
        border-radius: 8px 0 0 8px;
      }
      &:last-child {
        border-radius: 0 8px 8px 0;
      }
    }
  }

  .ant-pagination-item-active {
    border-color: $primary-color;
  }
}

/* Drawer */

.ant-drawer-header {
  background-color: $body-bg;
  box-shadow: $primary-shadow;
  padding: 1.5rem 2rem;
}

.ant-drawer-content {
  background-color: $body-bg;
  .ant-drawer-close {
    background-color: $danger-color;
    top: 1rem;
    right: 1rem;
    border-radius: 50%;
    padding: 10px;
    font-size: 13px;
    color: #ffffff;
  }
}

/* Modal */
.ant-modal-wrap {
  z-index: 1001;
}

/* Spinner */

.ant-spin.ant-spin-lg.ant-spin-spinning {
  display: block;
  margin: 0 auto;

  .ant-spin-dot {
    font-size: 4rem;
  }
}

// Menu

.ant-menu-item-disabled {
  display: none !important;
}

// Switch

.ant-switch {
  .ant-switch-handle::before {
  }
}
.ant-switch-checked {
  background-color: $primary-color;
  .ant-switch-handle::before {
  }
}

//  Checkbox
.ant-checkbox-wrapper {
  .ant-checkbox-inner {
    height: 24px;
    width: 24px;
    &::after {
      left: 30% !important;
    }
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background: #2543e7 !important;
    }
  }
}

// Tabs
.ant-tabs-top > .ant-tabs-nav::before {
  border: none;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
  padding: 10px 20px;
  background-color: #FFFFFF;
  box-shadow: $primary-shadow;
  border-radius: 8px;
}

.ant-tabs-ink-bar {
  background-color: transparent;
}

.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 5px 20px;
  border-radius: 8px;
  .ant-tabs-tab-btn:hover {
    color: $secondary-color;
  }
  &.ant-tabs-tab-active {
    background-color: $card-primary-bg !important;
    .ant-tabs-tab-btn {
      color: $secondary-color;
      font-family: DaxBold, sans-serif;
      &:hover {
        color: $secondary-color;
      }
    }
  }
}