@import "src/styles/variables";

.subscription-list {
    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .btn-edit {
        padding: 4px 10px !important;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.5;
    }

    .subscription__row {
        cursor: pointer;
        &:hover {
            .btn-edit {
                visibility: visible;
                opacity: 1;
            }
        }
    }   
}
