@import "src/styles/variables";

.corporates-wrapper {

}

.corporates-wrapper__stats {
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: $primary-shadow;
  padding: 3rem;
}

.corporates-wrapper__stats-row > .corporates-wrapper__stats-col {
  .corporates-wrapper__stats-card  {
    position: relative;
    padding: 2rem;
    text-align: left;
    border-radius: 8px;
    i.background-icon {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      font-size: 3.5rem;
    }
  }
  .corporates-wrapper__stats-card.corporates-wrapper__staffs {
    background-color: $card-primary-bg;
    i:not(.background-icon) {
      color: $secondary-color;
      border-radius: 50em;
      background-color: #AAB8E3;
      padding: 1rem 1.1rem;
      font-size: 2rem;
      line-height: 1rem;
    }
    i.background-icon {
      color: #AAB8E3;
    }
  }
  .corporates-wrapper__stats-card.corporates-wrapper__doctors {
    background-color: $card-secondary-bg;
    i:not(.background-icon) {
      color: #dea571;
      border-radius: 50em;
      background-color: #edb887;
      padding: 1rem 1.2rem;
      font-size: 2rem;
      line-height: 1rem;
    }
    i.background-icon {
      color: #edb887;
    }
  }
  .corporates-wrapper__stats-card.corporates-wrapper__assistants {
    background-color: $card-tertiary-bg;
    i:not(.background-icon) {
      color: #546C41;
      border-radius: 50em;
      background-color: #A2CC82;
      padding: 1.25rem 1rem;
      font-size: 2rem;
      line-height: 1rem;
    }
    i.background-icon {
      color: #A2CC82;
    }
  }
}


.corporates-wrapper__search.ant-input-affix-wrapper {
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: $primary-shadow;
  border: none;
  .ant-input-search-icon::before {
    border: none;
  }
}

