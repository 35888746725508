@import "src/styles/variables";

.object-links-card {
  .object-links-card__locators {
    background-color: #FFFFFF;
    padding: 1rem;
    >.object-links-card__locator-row {
      padding: 10px;
      color: $primary-black;
      &:not(:last-child) {
        border-bottom: 1px solid $primary-border;
      }
      span.anticon.anticon-delete {
        background: $light-grey;
        color: $danger-color;
        cursor: pointer;
        padding: 8px;
        border-radius: 50em;
      }
    }
  }
}
