@import "src/styles/variables";

.comments {
  background-color: white;
  border-radius: 8px;
  box-shadow: $primary-shadow;
  padding: 1.75rem 2.75rem;
  .title {
    color: rgba($color: $primary-black, $alpha: 0.4);
    font-size: 16px;
  }

  
  .comment {
    position: relative;
    .deleting {
      position: absolute;
      background: rgba($color: white, $alpha: 0.8);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    } 

    img {
      height: 2rem;
      width: 2rem;
    }

    .icon-edit {
      padding: 6px;
      font-size: 15px;
    }

    .name {
      font-weight: bolder;
      font-size: 18px;
      margin-right: 12px;
    }

    .date {
      color: rgba($color: $primary-black, $alpha: 0.4);
      font-size: 12px;
    }

    .description {
      color: #4F4F4F;
    }
  }

  .icon-delete {
    background: $danger-color;
    padding: 6px;
    border-radius: 7px !important;
    cursor: pointer;
    &::before {
      color: white;
    }
  }
}
