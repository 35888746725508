@import "variables";
@import "themes";
@import "antOverrides";
@import "helpers";
@import "icons";

/* General component styles */

@font-face {
  font-family: DaxRegular;
  src: url("../assets/fonts/DMSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: DaxMedium;
  src: url("../assets/fonts/DMSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: DaxBold;
  src: url("../assets/fonts/DMSans-Bold.ttf") format("truetype");
}

body {
  color: $primary-black;
}

body,
h1,
h2,
h3,
h4,
h5,
button,
input {
  font-family: DaxMedium, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: DaxBold, sans-serif;
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.5rem;
}

h4,
.h4 {
  font-size: 1.2rem;
}

h5,
.h5 {
  font-size: 1rem;
}

h6,
.h6 {
  font-size: 0.9rem;
}

.cursor-pointer {
  cursor: pointer;
}

.a4d-router-wrapper {
  @include themed() {
    background-color: setStyle("body-bg");
    color: setStyle("text-color");
  }
}

.app-wrapper {
  @include themed() {
    margin: setStyle("app-wrapper-margin");
    width: setStyle("app-wrapper-width");
  }
  padding: 2rem;
}

.icon-edit {
  background-color: $primary-color;
  padding: 0.6rem;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  &::before {
    color: white;
  }
}

.text-success {
  color: $success-color !important;
}

.text-warning {
  color: $warning-color !important;
}

.text-danger {
  color: $danger-color !important;

  &::before {
    color: $danger-color !important;
  }
}
