@import "src/styles/variables";

.users {
    .header {
        display: flex;
        justify-content: space-between;

        p {
            font-size: 22px;
            margin: 0px;
        }

        button {
            .anticon.anticon-plus {
                font-size: 18px;
                vertical-align: middle;
            }
        }
    }
}