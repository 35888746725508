@import "src/styles/variables";

.object-link-list__table {
  padding: 2rem;
  box-shadow: $primary-shadow;
  background-color: #FFFFFF;
  border-radius: 8px;
  .ant-table-wrapper .ant-table .ant-table-content table {
    border-spacing: 0;
  }

  .ant-table-wrapper tbody.ant-table-tbody tr {
    box-shadow: none;
  }
  .ant-table-wrapper tbody.ant-table-tbody tr > td,
  .ant-table-wrapper thead.ant-table-thead tr > th {
    padding: 10px;
  }
  .ant-table-wrapper thead.ant-table-thead tr {
    margin-bottom: 2rem;
    th {
      background-color: #FFFFFF;
      border-bottom: 1px solid $primary-border;
    }
  }

  .ant-table-row-expand-icon-cell {
    display: none;
  }
}
