@import "src/styles/variables";

.corporate-verification-form__body {
  background-color: #FFFFFF;
  box-shadow: $primary-shadow;
  padding: 2rem;
}

.corporate-verification-form__body {
  .ant-checkbox-wrapper {
    font-size: 1.1rem;
    margin-bottom: 1rem;

    .ant-checkbox-inner {
      width: 20px;
      height: 20px;

      &::after {
        left: 26%;
      }
    }
  }
}
