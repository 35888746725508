@import "src/styles/variables";

.corporate-form {

}

.corporate-form__fields {
  background-color: #FFFFFF;
  box-shadow: $primary-shadow;
  padding: 2rem;
  margin-bottom: 2rem;
}
