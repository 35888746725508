@import "src/styles/variables";

.corporate-detail {
}

.corporate-detail__header {
  margin-bottom: 2rem;
}

.corporate-detail__body {
  padding: 2rem 3rem;
  background-color: #ffffff;
  box-shadow: $primary-shadow;
}

.corporate-detail__label {
  display: block;
  color: $secondary-black;
  margin-bottom: 10px;
}

.corporate-detail__value {
  font-weight: bold;
}

img.corporate-detail__logo {
  width: 100px;
  object-fit: cover;
  border-radius: 4px;
}

.corporate-detail__attachment {
  width: 100px;
}

.cursor-pointer {
  cursor: pointer;
}

.plain-view {
  .ant-modal-content {
    background: transparent;
    box-shadow: none;
  }
  .ant-modal-body {
    padding: 2.5rem;
    background-color: transparent;
    iframe {
      width: 100%;
      height: 70vh;
    }
  }
  .ant-modal-footer {
    display: none;
  }

  .ant-modal-close-x {
    color: white;
  }
}

.rpv-core__viewer {
  height: 80vh !important;

}



.attachment {
  
  .corporate-detail__value {
    position: relative;

  }
  .attachment__full-view {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
}