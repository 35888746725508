@import "src/styles/variables";
@import "src/styles/themes";

.app-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: $primary-shadow;
  padding: 1.5rem 2rem;
  z-index: 1;
  @include themed() {
    display: setStyle('header-display');
  }
}

.app-header__logo {
  width: 70%;
}

.app-header__menu {
  padding: 0 1rem;
}

.app-header__logo-wrapper {
  border-right: 1px solid $primary-border;
}

.app-header__title {
  padding-left: 1rem;
}

.ant-btn.secondary-btn.app-header__notification-btn {
  position: relative;
  padding: 5px 10px;
  font-size: 1rem;
  border-radius: 4px;
}

.app-header__notification-count {
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: $danger-color;
  top: -5px;
  right: -5px;
  border-radius: 50%;
  font-size: 10px;
}
