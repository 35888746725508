@import "src/styles/variables";

.object-link-form {
  position: relative;
  height: calc(100vh - 4rem);
  h5 {
    color: #FFFFFF;
  }

  .radio-input-field {
    margin-bottom: 1rem;
  }
}

.object-link-form__model, .object-link-form__form-wrapper {
  height: 80vh;
}

.object-link-form__form-wrapper {
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0;
  }
}

.object-link-form__model, .object-link-form__form {
  padding: 2rem;
  background-color: $card-bg--dark;
  border-radius: 4px;

  form {
    height: 73vh;
    overflow: hidden scroll;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
}

.object-link-form__form {
  width: 100%;
  margin-bottom: 1rem;
}

