@import "src/styles/variables";

.dental-object-detail {

}

.dental-object-detail__body {
  padding: 2rem;
  background-color: #FFFFFF;
  box-shadow: $primary-shadow;
  margin-top: 2rem;
  border-radius: 8px;
}

.dental-object-detail__field {
  margin-bottom: 15px;
}

.dental-object-detail__label {
  color: $secondary-black;
  margin-bottom: 5px;
}

.dental-object-detail__value {
  font-family: DaxBold, sans-serif;
  font-size: 16px;
}

.dental-object-detail__color {
  width: 200px;
  height: 30px;
  display: block;
  margin-top: 10px;
  border: 1px solid $primary-border;
  border-radius: 4px;
}

