@import "src/styles/variables";

.view-subscription {
    box-shadow: $primary-shadow;
    background: white;
    padding: 1.75rem;
    label {
        color: #9E9E9E;
        font-weight: lighter !important;
    }

    p {
        font-size: 16px;
        margin-top: 6px;
    }
}