@import "src/styles/variables";
@import "src/styles/themes";

.radio-input-field {
  margin-bottom: 1.5rem;
  .ant-radio-wrapper {
    @include themed() {
      color: setStyle('input-text-color')
    }
  }

  .radio-input-field__title {
    color: $secondary-black;
    margin-bottom: 10px;
  }
}
