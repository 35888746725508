@import "src/styles/variables";

.roles-and-responsibility {
    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            color: $secondary-color;
        }
    }
}