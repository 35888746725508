@import "src/styles/variables";

.subscription-form {
    .wrapper {
        background: white;
        padding: 2.5rem;
        box-shadow: $primary-shadow;
    }

    .controller {
        display: flex;
        justify-content: flex-end;
        margin-top: 1.75rem;
    }
}