@import "src/styles/variables";

.support {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
    }


    .ant-select-selector {
        width: 120px !important;
    }
}