@import "src/styles/variables";

.object-link-preview {
  height: 100%;
}

#a4d-object-link {
  width: 100% !important;
  height: 100%;
  &:focus {
    outline: none;
  }
}
