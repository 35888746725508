@import "src/styles/variables";

.auth-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 2rem;
  bottom: 0;
}
