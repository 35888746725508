@import "src/styles/variables";
@import "src/styles/themes";

.app-sidebar {
	position: fixed;
	width: 18%;
	top: 6rem;
	left: 1rem;
	height: calc(100vh - 5rem);
	background-color: #ffffff;
	box-shadow: $primary-shadow;
	padding: 2rem 1rem;
	border-radius: 8px;
	z-index: 1;
	color: black;

	@include themed() {
		display: setStyle("sidebar-display");
	}
	.app-sidebar__menubar.ant-menu:not(.ant-menu-horizontal) {
		position: relative;
		height: calc(100vh - 9rem);
		border: none;

		.sub-menu__content-wrapper {
			.name,
			.icon {
				z-index: 1;
				position: relative;
			}

			&:hover {
				.screen-over {
					width: 100%;
					border-radius: 8px;
				}
				.name,
				.icon {
					color: white;
				}
			}
		}

		.ant-menu-item {
			border-radius: 8px;
			padding: 5px 5px 5px 15px;
			height: auto;
			margin: 0 0 0.2rem;
			color: $primary-black;
			position: relative;
			overflow: hidden;
			.name,
			.icon {
				z-index: 1;
				position: relative;
			}

			&:hover {
				.screen-over {
					width: 100%;
					border-radius: 8px;
				}
				.name,
				.icon {
					color: white;
				}
			}
			i {
				width: 20px;
				display: inline-block;
				font-size: 14px;
			}
			&.ant-menu-item-selected {
				position: relative;
				background-color: $secondary-color;
				color: #ffffff;
				&:after {
					border: none;
				}
				&::before {
					position: absolute;
					right: 15px;
					top: 40%;
					content: "";
					background-color: $primary-color;
					width: 7px;
					height: 7px;
					border-radius: 50%;
				}
			}
		}
	}

	.ant-menu:not(.ant-menu-horizontal) .ant-menu-item.app-sidebar__profile {
		background-color: $sidebar-profile-bg;
		padding: 1rem !important;
		position: absolute;
		bottom: 10px;
	}

	.app-sidebar__profile {
		.app-sidebar__profile-img {
			width: 100%;
			border-radius: 50%;
		}

		.app-sidebar__profile-info h5 {
			margin: 0;
			line-height: 1rem;
		}
	}

	.app-sidebar__profile-badge {
	}
}

.ant-menu.ant-menu-sub.ant-menu-vertical {
}

.screen-over {
	background-color: $secondary-color;
	width: 0%;
	height: 100%;
	position: absolute;
	transition: width 0.6s;
	left: 0px;
	top: 0px;
	z-index: 0;
}

.app-sidebar__submenu-item i {
	text-align: center;
	display: inline-block;
	width: 20px;
	font-size: 14px;
	margin-right: 5px;
}

.app-sidebar__menu-title {
	display: inline-block;
	margin-left: 10px;
}

.ant-menu-submenu.ant-menu-submenu-vertical {
	border-radius: 8px;
	&.ant-menu-submenu-selected {
		position: relative;
		background-color: $secondary-color;
		color: #ffffff;
		.ant-menu-submenu-arrow {
			display: none;
		}

		&:after {
			border: none;
		}
		&::before {
			position: absolute;
			right: 15px;
			top: 40%;
			content: "";
			background-color: $primary-color;
			width: 7px;
			height: 7px;
			border-radius: 50%;
		}
	}
}

.ant-menu.ant-menu-sub.ant-menu-vertical {
	padding: 0.6rem;
	min-width: 220px !important;
	.ant-menu-item {
		border-radius: 8px;
		padding: 5px 5px 5px 15px;
		height: auto;
		margin: 0 0 0.2rem;
		color: $primary-black;
		position: relative;
		overflow: hidden;
		.name,
		.icon {
			z-index: 1;
			position: relative;
		}

		&:hover {
			.screen-over {
				width: 100%;
				border-radius: 8px;
			}
			.name,
			.icon {
				color: white;
			}
		}
		i {
			width: 20px;
			display: inline-block;
			font-size: 14px;
		}
		&.ant-menu-item-selected {
			position: relative;
			background-color: $secondary-color;
			color: #ffffff;
			&:after {
				border: none;
			}
			&::before {
				position: absolute;
				right: 15px;
				top: 40%;
				content: "";
				background-color: $primary-color;
				width: 7px;
				height: 7px;
				border-radius: 50%;
			}
		}
	}
}
