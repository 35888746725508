/* Color palette */
$primary-color: #00AE89;
$secondary-color: #1C2F80;
$tertiary-color: #7B0CD0;
$progress-bg: #478F9D;
$progress-text-bg: #198987;
$light-white: #E2E2E2;
$primary-light-color: #00AE8980;
$primary-black: #2B2B2B;
$primary-white: #FFFFFF;
$secondary-black: #8E8E8E;
$primary-border: #BEBEBE;
$dark-blue: #22509D;
$light-grey: #00000015;
$dark-grey: #8898AA;
$success-color: #00AE89;
$danger-color: #FE0000;
$warning-color: #F78D3D;
$body-bg: #FEFCF7;
$table-bg: #F7FAFC;
$input-bg: #F8FBFC;
$card-primary-bg: #CED9FD;
$card-secondary-bg: #F6CBA1;
$card-tertiary-bg: #BDDBA6;
$sidebar-profile-bg: #F0F1F7;
$sidebar-display: block;
$header-display: block;
$app-wrapper-margin: 6rem 0 0 20%;
$app-wrapper-width: 80%;
$label-color: #8E8E8E;

/* Dark mode variables */

$primary-text--dark: #FFFFFF;
$body-bg--dark: #2B2B2B;
$input-bg--dark: #3A3A3A;
$card-bg--dark: #3A3A3A;
$sidebar-display--dark: none;
$header-display--dark: none;
$app-wrapper-margin--dark: 0 0 0 0;
$app-wrapper-width--dark: 100%;

/* Shadows */
$primary-shadow: 0px 2px 20px #5858580D;
$secondary-shadow: 0px 10px 25px #28388F1A;

/* Spacer variables */
$spacer-x : 1rem;
$spacer-y : 1rem;
