@import "src/styles/variables";

.object-3d-form {
  position: relative;
  height: calc(100vh - 4rem);
  h5 {
    color: #FFFFFF;
  }

  .object-3d-form__submit-wrapper {
    position: absolute;
    top: -2rem;
    right: 10px;
  }
}

.object-3d-form__model, .object-3d-form__form {
  margin-top: 2rem;
  padding: 2rem;
  background-color: $card-bg--dark;
  border-radius: 4px;
  height: 80vh;

}

.object-3d-form__form {
  overflow: hidden scroll;
  &::-webkit-scrollbar {
    width: 0;
  }
}

