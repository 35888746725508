@import "src/styles/variables";

.date-picker-field .ant-picker {
  border-radius: 8px;
  padding: 10px 12px;
  width: 100%;
  border-color: $primary-border;
  .ant-picker-input > input {
    font-size: 16px;
  }

  .icon-calendar {
    font-size: 18px;
    &::before {
      color: #8E8E8E;
    }
  }
}



.date-picker-field__title {
  margin: 0 0 6px 0;
  color: $dark-grey;
  font-weight: 500;
}
